// *******************************************
// *******************************************

// Base icon styles

// *******************************************
// *******************************************

// @vars
$icon-colors: $all-colors; // settings/colors
$icon-default-size: 24;

// <span class="icon [icon--[name] icon--rotate-[val] icon--[color]]">
//     <svg />
// </span>

.icon {
    display: inline-block;
    width: rem-calc($icon-default-size);
    height: rem-calc($icon-default-size);
    margin: rem-calc(0 4);
    background: transparent;
    fill: $black;
}

.icon__white {
    fill: $white;
}

// color modifiers
@each $color-key, $color in $icon-colors {
    .icon--#{$color-key} {
        fill: $color;
    }

    .icon--hover-#{$color-key}:hover,
    .icon--hover-#{$color-key}:focus,
    .icon--hover-#{$color-key}:hover *,
    .icon--hover-#{$color-key}:focus * {
        fill: $color;
    }
}

// size modifiers
.icon--size14 {
    width: rem-calc(14);
    height: rem-calc(14);
    line-height: rem-calc(14);
}

.icon--size16 {
    width: rem-calc(16);
    height: rem-calc(16);
    line-height: rem-calc(16);
}

.icon--size24 {
    width: rem-calc(24);
    height: rem-calc(24);
    line-height: rem-calc(24);
}

.icon--size32 {
    width: rem-calc(32);
    height: rem-calc(32);
    line-height: rem-calc(32);
}

.icon--size60 {
    width: rem-calc(60);
    height: rem-calc(60);
    line-height: rem-calc(60);
}

.icon--size72 {
    width: rem-calc(72);
    height: rem-calc(72);
    line-height: rem-calc(72);
}

// spin icon modifier
.icon--spin {
    svg {
        animation: icon-spin 450ms infinite linear;
    }
}

@keyframes icon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
