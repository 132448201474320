// *******************************************
// *******************************************

// FAB component styles

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;
$unit-touch: $unit-touch;

// settings/z-indexes
$index-fab: $index-fab;

// component settings
$fab-size: $unit-touch * 1.25;
$fab-shadow: 0 4px 10px rgba($black, 0.35);
$fab-shadow-hover: 0 6px 18px rgba($black, 0.5);
$fab-margin: $unit-default-spacing * 2;

.fab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem-calc($fab-size);
    height: rem-calc($fab-size);
    margin: rem-calc($fab-margin);
    padding: rem-calc($unit-default-spacing);
    box-shadow: $fab-shadow;
    border-radius: 50%;
    background: $primary;
    color: $white;
    transition: box-shadow 250ms $ease-in-out-quad;

    &:hover,
    &:focus {
        box-shadow: $fab-shadow-hover;
    }
}

// layout fix for <button> elements
.fab__layout {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem-calc($fab-size - ($unit-default-spacing * 2));
    height: rem-calc($fab-size - ($unit-default-spacing * 2));
}

.fab--alloy--primary {
    background: $alloy-primary;
}

.fab--secondary {
    background: $secondary;
}

.fab--gray--200 {
    background: $gray-200;
}

.fab--symbolic-error {
    background: $symbolic-error;
}

.fab--fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: $index-fab;
}
