// *******************************************
// *******************************************

// Dropdown component

// *******************************************
// *******************************************

// @vars
// settings/z-indexes
$stack-top: $stack-top;
$stack-bot: $stack-bot;

// settings/colors
$white: $white;
$gray-200: $gray-200;

.dropdown {
    display: inline-flex;
    position: relative;
    z-index: $stack-bot;
}

.dropdown__content {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: $stack-top;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    box-shadow: $shadow-lift;
    border: 1px solid $gray-200;
    background: $white;

    &::before,
    &::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        height: 0;
        width: 0;
        border: solid transparent;
        pointer-events: none;
    }

    &::before {
        margin-left: -9px;
        border-width: 9px;
        border-color: rgba($gray-200, 0);
        border-bottom-color: $gray-200;
    }

    &::after {
        margin-left: -8px;
        border-width: 8px;
        border-color: rgba($white, 0);
        border-bottom-color: $white;
    }

    // visibility modifier
    .dropdown--visible & {
        visibility: visible;
        opacity: 1;
    }
}

.dropdown__trigger {
    &:focus {
        outline: 0;
    }

    .dropdown--visible & {
        .icon {
            fill: $primary !important;
        }
    }
}

.dropdown__mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $stack-bot;
    display: none;
    background: transparent;

    .dropdown--visible & {
        display: block;
    }
}
