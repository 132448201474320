// *******************************************
// *******************************************

// Position utilities

// *******************************************
// *******************************************

.u-pos-fit,
.u-pos-abolute-center,
.u-pos-absolute {
    position: absolute !important;
}

// Element will be centered to its nearest relatively-positioned
// ancestor.
.u-pos-fixed-center,
.u-pos-abolute-center {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.u-pos-fit,
.u-pos-fullscreen {
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    right: 0 !important;
    top: 0 !important;
}

// 1. Make sure fixed elements are promoted into a new layer, for performance reasons
.u-pos-fullscreen,
.u-pos-fixed-center,
.u-pos-fixed {
    backface-visibility: hidden; // 1
    position: fixed !important;
}

.u-pos-relative {
    position: relative !important;
}

.u-pos-static {
    position: static !important;
}
