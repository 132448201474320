// *******************************************
// *******************************************

// Spacing utilities

// *******************************************
// *******************************************

/////////////////////////////////////////////
// Margins
/////////////////////////////////////////////

// @vars
// directional values
@use "sass:math";

$margin-directions: top right bottom left;
$max-margin-spacing: 72 !default;
$margin-spacing-threshhold: 4 !default;
$margin-spacing-buffer: $max-margin-spacing * $margin-spacing-threshhold;

// Margin Function
// Outputs .u-mx {margin:1px through margin:10px}
@for $i from 0 through $max-margin-spacing {
    $value: (math.div($margin-spacing-buffer, $max-margin-spacing) * $i) * 1;

    // only return if withing max margin range
    @if $value <= $max-margin-spacing {
        .u-m#{$i * $margin-spacing-threshhold} {
            margin: rem-calc($value) !important;
        }
    }
}

@for $i from 0 through $max-margin-spacing {
    $value: (math.div($margin-spacing-buffer, $max-margin-spacing) * $i) * 1;

    // only return if withing max margin range
    @if $value <= $max-margin-spacing {
        @include breakpoints(#{".u-m" + $i * $margin-spacing-threshhold}) {
            margin: rem-calc($value) !important;
        }
    }
}

// Single Side Margin Function
// Outputs .u-mtopx {margin-top:1px through margin-top:20px}
// Outputs .u-mrightx {margin-right:1px through margin-right:20px}
// Outputs .u-mbottomx {margin-bottom:1px through margin-bottom:20px}
// Outputs .u-mleftx {margin-left:1px through margin-left:20px}
@each $direction in $margin-directions {
    @for $i from 0 through $max-margin-spacing {
        $value: (math.div($margin-spacing-buffer, $max-margin-spacing) * $i) *
            1;

        // only return if withing max margin range
        @if $value <= $max-margin-spacing {
            .u-m#{$direction}#{$i * $margin-spacing-threshhold} {
                margin-#{$direction}: rem-calc($value) !important;
            }
        }
    }
}

@each $direction in $margin-directions {
    @for $i from 0 through $max-margin-spacing {
        $value: (math.div($margin-spacing-buffer, $max-margin-spacing) * $i) *
            1;

        // only return if withing max margin range
        @if $value <= $max-margin-spacing {
            @include breakpoints(
                #{".u-m" + $direction}#{$i * $margin-spacing-threshhold}
            ) {
                margin-#{$direction}: rem-calc($value) !important;
            }
        }
    }
}

////////////////////////
// Extra helpers
////////////////////////
.u-mauto {
    margin-right: auto !important;
    margin-left: auto !important;
}

/////////////////////////////////////////////
// Padding
/////////////////////////////////////////////

// @vars
// directional values
$padding-directions: top right bottom left;
$max-padding-spacing: 48 !default;
$padding-spacing-threshhold: 4 !default;
$padding-spacing-buffer: $max-padding-spacing * $padding-spacing-threshhold;

// Padding Function
// Outputs .u-px {padding:1px through padding:10px}
@for $i from 0 through $max-padding-spacing {
    $value: (math.div($padding-spacing-buffer, $max-padding-spacing) * $i) * 1;

    // only return if withing max padding range
    @if $value <= $max-padding-spacing {
        .u-p#{$i * $padding-spacing-threshhold} {
            padding: rem-calc($value) !important;
        }
    }
}

@for $i from 0 through $max-padding-spacing {
    $value: (math.div($padding-spacing-buffer, $max-padding-spacing) * $i) * 1;

    // only return if withing max padding range
    @if $value <= $max-padding-spacing {
        @include breakpoints(#{".u-p"}#{$i * $padding-spacing-threshhold}) {
            padding: rem-calc($value) !important;
        }
    }
}

// Single Side Padding Function
// Outputs .u-ptopx {padding-top:1px through padding-top:20px}
// Outputs .u-prightx {padding-right:1px through padding-right:20px}
// Outputs .u-pbottomx {padding-bottom:1px through padding-bottom:20px}
// Outputs .u-pleftx {padding-left:1px through padding-left:20px}
@each $direction in $padding-directions {
    @for $i from 0 through $max-padding-spacing {
        $value: (math.div($padding-spacing-buffer, $max-padding-spacing) * $i) *
            1;

        // only return if withing max padding range
        @if $value <= $max-padding-spacing {
            .u-p#{$direction}#{$i * $padding-spacing-threshhold} {
                padding-#{$direction}: rem-calc($value) !important;
            }
        }
    }
}

@each $direction in $padding-directions {
    @for $i from 0 through $max-padding-spacing {
        $value: (math.div($padding-spacing-buffer, $max-padding-spacing) * $i) *
            1;

        // only return if withing max padding range
        @if $value <= $max-padding-spacing {
            @include breakpoints(
                #{".u-p"}#{$direction}#{$i * $padding-spacing-threshhold}
            ) {
                padding-#{$direction}: rem-calc($value) !important;
            }
        }
    }
}
