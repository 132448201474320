// *******************************************
// *******************************************

// Scene button component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;

// settings/z-indexes
$stack-top: $stack-top;

// settings/stylistic
$shadow: $shadow;

// settings/colors
$utility-colors: $utility-colors;

// component vars
$scene-button-dimension: 160;
$scene-button-radius: 4;
$scene-button-icon-dimension: 56;
$scene-button-colors: $utility-colors;

.scenes__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.scene-button {
    margin: 7px 0;
    width: 170px;
    min-width: rem-calc(160);
    min-height: rem-calc(100);
    padding: rem-calc($unit-default-spacing);
    box-shadow: $shadow;
    border-radius: rem-calc($scene-button-radius);
    background: $gray-200;
    cursor: pointer;
    transition: background-color 200ms ease, box-shadow 200ms ease;

    &:hover,
    &:focus {
        box-shadow: $shadow-lift-light;
        background: shade($gray-200, 5%);
    }

    &.scene-button--loading {
        box-shadow: none;
        cursor: progress;
    }
}

// color modifiers
@each $color-key, $color in $scene-button-colors {
    .scene-button--#{$color-key} {
        background: $color;

        &:hover,
        &:focus {
            background: shade($color, 5%);
        }

        &.scene-button--loading {
            background: tint($color, 20%);
        }
    }
}

.scene-button__detail {
    color: $white;
}

.scene-button__detail__name {
    padding-left: 7px;
    font-size: 14;
    line-height: 1.2;
    font-family: Open Sans;
}

.scene-button__detail__icon__default {
    width: rem-calc($scene-button-icon-dimension);
    height: rem-calc($scene-button-icon-dimension);
    .scene-button--loading & {
        display: none;
    }
}

.scene-button__detail__icon__loading {
    margin: 18px 0px 20px 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
