// *******************************************
// *******************************************

// App Header Search Button component

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-app-header-height: $unit-app-header-height;

// Still in use as of 6/23

.app-search-button {
    display: block;
    width: 100%;
    height: rem-calc($unit-app-header-height);
    background: $gray-900;
    color: $gray-400;
}

.app-search-button__layout {
    display: flex;
    align-items: center;
    padding: rem-calc(0 ($unit-default-spacing * 2));
}

// shortcut key hint
// hide on mobile
// show on desktop
.app-search-button__shortcut-hint {
    display: none;

    @include min-screen($breakpoint-md) {
        display: block;
        width: rem-calc(51);
        height: rem-calc(15);
    }
}

.app-search-button__icon {
    margin-right: rem-calc($unit-default-spacing);
    fill: $gray-400;
}

.app-search-button__placeholder {
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    text-align: left;
    @include sans-light;

    @include min-screen($breakpoint-md) {
        flex: 1 0 auto;
    }
}
