// *******************************************
// *******************************************

// Panel component

// *******************************************
// *******************************************

// @vars
// settings/colors
$white: $white;
$gray-200: $gray-200;
$panel-colors: $all-colors; // settings/colors

// component vars
$panel-spacing: $unit-default-spacing; // settings/layout-units
$panel-header-height: $unit-touch; // settings/layout-units
$panel-shadows: $shadows; // seettings/stylistic

/////////////////////////////////////////////
// Main panel container
/////////////////////////////////////////////

// panel container element
// @defaults
.panel {
    margin-bottom: rem-calc($panel-spacing * 2);
    border: 1px solid $gray-200;
    background: $white;
}

// shadow modifiers
@each $shadow-key, $shadow in $panel-shadows {
    .panel--#{$shadow-key} {
        box-shadow: $shadow;
    }
}

.panel__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc($panel-spacing ($panel-spacing * 2));
}

.panel__header--border {
    border-bottom: 1px solid $gray-200;
}

.panel__header--gray-050 {
    background: $gray-050;
}

.panel__header--gray-100 {
    background: $gray-100;
}

.panel__header--gray-200 {
    background: $gray-200;
}

.panel__header--gray-800 {
    background: $gray-800;
    color: $white;
}

.panel__content {
    padding: rem-calc($panel-spacing * 2);
}

// color modifiers
// .panel--gray-050 {
//     background : $gray-050;
// }

@each $color-key, $color in $panel-colors {
    .panel--#{$color-key} {
        background: $color;
    }
}

.panel--symbolic-error {
    color: $white;
}
