// *******************************************
// *******************************************

// Dialog component

// *******************************************
// *******************************************

// @vars
// component vars
$dialog-sm-size: 320;
$dialog-md-size: 600;

.dialog {
    position: relative;
    width: 100%;
}

.dialog__overlay {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: $stack-top;
    opacity: 0;
    visibility: hidden;

    .dialog--show-overlay & {
        opacity: 1;
        visibility: visible;
    }
}

.dialog--sm {
    max-width: rem-calc($dialog-sm-size);
}

.dialog--md {
    max-width: rem-calc($dialog-md-size);
}
