// *******************************************
// *******************************************

// Toolbar component

// *******************************************
// *******************************************

// @vars

// settings/breakpoints
$breakpoint-md: $breakpoint-md;

// settings/layout-units
$unit-touch: $unit-touch;
$unit-app-header-height: $unit-app-header-height;
$unit-sidebar-header-md-height: $unit-sidebar-header-md-height;

// settings/z-indexes
$stack-top: $stack-top;

// settings/colors
$white: $white;
$gray-200: $gray-200;
$gray-200: $gray-200;
$gray-600: $gray-600;

// settings/stylistic
$shadow: $shadow;

// Still in use as of 6/23

// container element
.toolbar {
    position: relative;
    z-index: $stack-top;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: rem-calc($unit-touch);
    padding: rem-calc($unit-default-spacing ($unit-default-spacing * 2) 0);
    border-bottom: 1px solid $gray-100;
    background: $white;

    @include min-screen($breakpoint-md) {
        padding: rem-calc(0 ($unit-default-spacing * 2));
    }

    > .toolbar__toolgroup {
        border-right: 1px solid $gray-200;
        border-left: 1px solid $gray-200;
    }
}

// extra element to constrain
// toolbar content width
.toolbar__constrain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

// page toolbar modifier
.toolbar--page {
    height: auto;
    padding: rem-calc(
        ($unit-default-spacing * 2) ($unit-default-spacing * 2) 0
    );
    border-bottom: 1px solid $gray-200;
    @include sans-bold;

    @include min-screen($breakpoint-md) {
        min-height: rem-calc($unit-sidebar-header-md-height);
        padding: rem-calc(0 ($unit-default-spacing * 4));
    }

    .toolbar__tab {
        @include min-screen($breakpoint-md) {
            height: rem-calc($unit-sidebar-header-md-height);
        }
    }
}

// toolbar sections
// groups toolbar areas
.toolbar__section {
    display: flex;
    align-items: center;
    order: 1;
}

// title
.toolbar__section--title {
    flex: 1 0 auto;
    order: 1;
    color: $gray-700;

    @include min-screen($breakpoint-md) {
        flex: 0 0 33.3333%;
    }
}

.toolbar__section--title__cannot_add_groups {
    flex: 1 0 auto;
    order: 1;
    color: $gray-700;

    @include min-screen($breakpoint-md) {
        flex: 1;
    }
}

.toolbar__section--title__link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: $gray-700;

    @include min-screen($breakpoint-md) {
        margin-left: rem-calc(-$unit-default-spacing * 2);
    }
}

.toolbar__section--title__link__icon {
    margin: 0;
    margin-right: rem-calc($unit-default-spacing);
}

// tabs
.toolbar__section--tabs {
    flex: 1 0 33.3333%;
    order: 3;
    margin: rem-calc(0 (-$unit-default-spacing * 2));

    @include min-screen($breakpoint-md) {
        justify-content: center;
        order: 2;
    }
}

.toolbar__section--tabs__cannot_add_groups {
    flex: 1 1 0%;
    order: 3;
    margin: rem-calc(0 (-$unit-default-spacing * 2));

    @include min-screen($breakpoint-md) {
        justify-content: flex-start;
        order: 2;
    }
}

.resident__content-width {
    > .toolbar__section--tabs {
        flex: 1 0 100%;
    }
}

// actions
.toolbar__section--actions {
    flex: 0 0 auto;
    order: 2;

    @include min-screen($breakpoint-md) {
        flex: 1 0 33.3333%;
    }
}

// add shadow modifier
.toolbar--shadow {
    box-shadow: $shadow;
}
.toolbar--shadow-top {
    box-shadow: $shadow-inverse;
}
.toolbar--faint__line {
    border-top: 1px solid #cad0da;
}

// toolbar actions list
.toolbar__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.toolbar__action {
    margin-left: rem-calc($unit-default-spacing * 2);
}

.toolbar__tabs {
    flex: 1 0 auto;

    @include min-screen($breakpoint-md) {
        flex: 0 0 auto;
    }
}

// toolbar tab items
// 1. fill up space on mobile
.toolbar__tab {
    flex: 1 0 auto; // 1.

    @include min-screen($breakpoint-md) {
        flex: 0 0 auto;
    }
}

// FAB button
.toolbar__fab {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: $stack-bot;
    transform: translateY(50%);
    margin: 0;
    margin-right: rem-calc($unit-default-spacing * 2);

    @include min-screen($breakpoint-md) {
        margin-right: rem-calc($unit-default-spacing * 4);
    }
}

// on smaller screens fix FAB
// to bottom of screen
.toolbar__fab--mobile-fixed {
    @include max-screen($breakpoint-md) {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: $stack-top;
        transform: none;
        margin: 0 rem-calc($unit-default-spacing * 2)
            rem-calc($unit-default-spacing * 2) 0;
    }
}
