// *******************************************
// *******************************************

// Select component
// needs to inherit from _input.scss

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-touch: $unit-touch;
$unit-default-spacing: $unit-default-spacing;

// settings/z-indexes
$stack-sub: $stack-sub;
$stack-bot: $stack-bot;
$stack-top: $stack-top;

// this is to style react-native-web select components
// since their api doesn't allow for font styles being applied
// directly to the component
select[data-focusable] {
    font-size: 14px;
    font-family: $open-sans !important;
    font-weight: 600;
}

.select {
    position: relative;
    z-index: $stack-bot;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0;
}

.select__input {
    position: relative;
    z-index: $stack-top;
    flex: 1 0 100%;
    height: 100%;
    padding: rem-calc(0 ($unit-default-spacing));
    border: 0;
    background: transparent;
}

@-moz-document url-prefix() {
    .select__input,
    input.input__input {
        padding-top: rem-calc($unit-default-spacing);
        padding-bottom: rem-calc($unit-default-spacing);
    }
}

.input--32px-base {
    .input__input--select {
        padding: 0;
    }
}

// Prevents really tall select dropdowns on table select filters
.select__table-select {
    height: auto;
    width: 100%;
    font-size: 14px;
}

// 480px because most mobile devices are 480px or smaller (http://javascriptkit.com/dhtmltutors/cssmediaqueries2.shtml)
// Does not work with our breakpoints.scss file
@include max-screen("480px") {
    .select__table-select {
        margin-bottom: 8px;
    }
}
