// *******************************************
// *******************************************

// Device Button component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-font-spacing: $unit-default-font-spacing;
$unit-default-spacing: $unit-default-spacing;

// component vars
$device-button-dimension: 64;

// Still in use as of 6/23
.device-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: rem-calc($device-button-dimension);
    min-height: rem-calc($device-button-dimension);
    padding: rem-calc($unit-default-spacing * 2);
    box-shadow: $shadow-lift-light;
    border: 1px solid $gray-200;
    border-radius: rem-calc($device-button-dimension * 0.5);
    background: $white;
    font-size: rem-calc(18);
    color: $gray-600;
    transition: background-color 300ms ease, box-shadow 300ms ease;
    @include sans-bold;

    &:hover {
        box-shadow: $shadow-lift-light-darken;
    }

    &:focus {
        box-shadow: $shadow-lift-light-darken;
        outline: none;
        background: $gray-050;
    }
}

.device-button--single {
    min-width: rem-calc(136);
}

.device-button__icon {
    fill: $primary;
}
