// *******************************************
// *******************************************

// Graph page layout

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;
$unit-app-header-height: $unit-app-header-height;

// settings/colors
$gray-050: $gray-050;
$gray-200: $gray-200;

// Still in use as of 6/23
.graph-page {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
}

.graph-page__graph-section {
    flex: 1 0 auto;
    padding: rem-calc($unit-default-spacing * 3);
    border-bottom: 1px solid $gray-200;
    background: $gray-050;

    @include min-screen($breakpoint-md) {
        height: calc(60vh - #{rem-calc($unit-app-header-height)});
        overflow: hidden;
    }
}

.graph-page__graph-section__graph-container {
    height: 47vh;
}

.graph-page__graph-nav {
    flex: 1 0 auto;
    padding: rem-calc(0 ($unit-default-spacing * 3));

    @include min-screen($breakpoint-md) {
        height: calc(40vh - #{rem-calc($unit-app-header-height)});
        overflow: hidden;
        overflow-y: auto;
    }
}

.graph-page__graph-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem-calc($unit-default-spacing * 2);
}
