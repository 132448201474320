// *******************************************
// *******************************************

// Display utilities

// *******************************************
// *******************************************

// @vars
$u-display-breakpoints: $breakpoints !default; // imported from settings/breakpoints

.u-block {
    display: block !important;
}

// breakpoints
@each $u-breakpoint-key, $u-display-breakpoint in $u-display-breakpoints {
    @include min-screen(#{$u-display-breakpoint}) {
        .u-block--#{$u-breakpoint-key} {
            display: block !important;
        }
    }
}

.u-hidden {
    display: none !important;
}

// breakpoints
@each $u-breakpoint-key, $u-display-breakpoint in $u-display-breakpoints {
    @include min-screen(#{$u-display-breakpoint}) {
        .u-hidden--#{$u-breakpoint-key} {
            display: none !important;
        }
    }
}

// 480px because most mobile devices are 480px or smaller (http://javascriptkit.com/dhtmltutors/cssmediaqueries2.shtml)
// Does not work with our breakpoints.scss file
@include max-screen("480px") {
    .u-visible--sm {
        display: block !important;
    }
}

.u-hidden-sm-only {
    @include screen(0, $breakpoint-md) {
        display: none;
    }
}

// Completely remove from the flow but leave available to screen readers.
.u-hidden-visually {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
}

.u-inline {
    display: inline !important;
}

// breakpoints
@each $u-breakpoint-key, $u-display-breakpoint in $u-display-breakpoints {
    @include min-screen(#{$u-display-breakpoint}) {
        .u-inline--#{$u-breakpoint-key} {
            display: inline !important;
        }
    }
}

// 1. Fix for Firefox bug: an image styled `max-width:100%` within an
// inline-block will display at its default size, and not limit its width to
// 100% of an ancestral container.
.u-inline-block {
    display: inline-block !important;
    max-width: 100%; // 1
}

.u-table {
    display: table !important;
}

.u-table-cell {
    display: table-cell !important;
}

.u-table-row {
    display: table-row !important;
}
