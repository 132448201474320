// *******************************************
// *******************************************

// App Main Nav component

// *******************************************
// *******************************************

// @vars

.app-nav__menu {
    padding: rem-calc(($unit-default-spacing * 3) 0);

    @include min-screen($breakpoint-md) {
        display: flex;
        align-items: center;
        padding: rem-calc(0 $unit-default-spacing);
    }
}

.app-nav__menu-desktop {
    display: none;
    @include min-screen($breakpoint-md) {
        display: flex;
    }
}

.app-nav__menu-mobile {
    overflow-y: auto;

    @include min-screen($breakpoint-md) {
        display: none;
    }
}

.app-nav__menu__item__a {
    padding: rem-calc(($unit-default-spacing * 3) ($unit-default-spacing * 3));
    display: block;

    line-height: 14px; // changes to match React layout
    font-size: 14px; // changes to match React layout
    // font-size: rem-calc(24);

    color: $gray-800;
    text-decoration: none;
    @include sans-bold;

    @include min-screen($breakpoint-md) {
        padding: rem-calc(0 $unit-default-spacing);
        // font-size: rem-calc(14);
        // line-height: rem-calc(14);
        color: $brand500;
        text-transform: uppercase;

        &:hover,
        &:focus {
            color: $primary-light;
        }
    }
}

// current link modifier
.app-nav__menu__item__a--current {
    color: $primary;

    @include min-screen($breakpoint-md) {
        color: $white;
    }
}

.app-nav__dropdown__desktop__content {
    display: none;
    z-index: 10;
    color: $primary;
    background-color: #272b2e;
    flex-direction: column;
    position: absolute;
    min-width: fit-content;
    border-radius: 4px;
    justify-content: flex-start;
    top: 35px;
}

.app-nav__dropdown__mobile__content {
    display: none;
    z-index: 10;
    background-color: $gray-200;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 1rem;
}

/* Links inside the dropdown */
.app-nav__dropdown__content,
.app-nav__dropdown__content a {
    color: $primary-light;
    font-size: 0.875rem;
    text-decoration: none;
    padding: 1.25rem 1rem;
    text-decoration: none;
    @include sans-bold;
    line-height: rem-calc(14);
}

/* Change color of dropdown links on hover */
.app-nav__dropdown__content:hover,
.app-nav__dropdown__content a:hover {
    color: $white;
}

.app-nav__menu__item:hover .app-nav__dropdown__item__a {
    color: $white;
}
