// *******************************************
// *******************************************

// Interactive helper classes

// *******************************************
// *******************************************

// Still referenced as of 6/23

// force onclick events in iOS
.onclick {
    cursor: pointer;
}
