// *******************************************
// *******************************************

// Stylistic settings

// *******************************************
// *******************************************

// box shadows
$shadow: 0 2px 4px rgba($black, 0.1);
$shadow-inverse: 0 -2px 4px rgba($black, 0.1);
$shadow-lift: 0 24px 44px rgba($black, 0.5);
$shadow-lift-light: 0 7px 15px rgba($black, 0.1);
$shadow-lift-light-darken: 0 7px 15px rgba($black, 0.2);
$shadows: (
    "shadow": $shadow,
    "shadow-inverse": $shadow-inverse,
    "shadow-lift": $shadow-lift,
    "shadow-lift-light": $shadow-lift-light,
);

// gradients
$gradient-image-overlay: linear-gradient(
    0deg,
    $primary-light 0%,
    $secondary 80%
);

// text-shadows
$text-shadow: 1px 1px rgba($black, 0.3);
$text-shadow-offset: -1px -1px rgba($black, 0.3);
$text-shadow0: 0 0 rgba($black, 0);
$text-shadow-white: 1px 1px rgba($white, 0.65);
$text-shadow-blur: 0 2px 4px rgba($black, 0.5);
$text-shadows: (
    "shadow": $text-shadow,
    "shadow--offset": $text-shadow-offset,
    "shadow0": $text-shadow0,
    "shadow--white": $text-shadow-white,
    "shadow--blur": $text-shadow-blur,
);
