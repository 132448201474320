/* overrides related to react-native-web */
div[class^="css-view-"] input,
div[class^="css-view-"] textarea {
    outline: none;
}

.app > .app__content {
    z-index: 0;
}

body.scroll-lock {
    overflow: hidden;
}
