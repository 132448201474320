// *******************************************
// *******************************************

// Group list views
// /groups
// map and list view

// *******************************************
// *******************************************

// @vars

// settings/breakpoints
$breakpoint-md: $breakpoint-md;

// Still in use as of 6/23

.group-card {
    margin: 24px;
    @include screen(0, $breakpoint-md) {
        flex-grow: 1;
        min-width: 400px;
        margin-top: 8px;
        margin-bottom: 0px;
    }
}
