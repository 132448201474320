// *******************************************
// *******************************************

// Sizing utilities

// *******************************************
// *******************************************

// @vars
// settings/layout-units
@use "sass:math";

$unit-default-content-width: $unit-default-content-width;
$unit-readability-content-width: $unit-readability-content-width;
$unit-base-font-size: $unit-base-font-size;

// size constraints
.u-size-max-width {
    max-width: rem-calc($unit-default-content-width) !important;
}

.u-size-readability {
    max-width: #{math.div($unit-readability-content-width, $unit-base-font-size)}em !important;
}

.u-width-auto {
    width: auto !important;
}

/////////////////////////////////////////////
// Proportional widths
// Specify the proportional width of an object.
// Intentional redundancy build into each set of unit classes.
// Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
//
//  1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
//     http://git.io/vllMD
//
/////////////////////////////////////////////

.u-size-1of12 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 12) !important;
}

.u-size-1of10 {
    flex-basis: auto !important;
    width: 10% !important;
}

.u-size-1of8 {
    flex-basis: auto !important;
    width: 12.5% !important;
}

.u-size-1of6,
.u-size-2of12 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 6) !important;
}

.u-size-1of5,
.u-size-2of10 {
    flex-basis: auto !important;
    width: 20% !important;
}

.u-size-1of4,
.u-size-2of8,
.u-size-3of12 {
    flex-basis: auto !important;
    width: 25% !important;
}

.u-size-3of10 {
    flex-basis: auto !important;
    width: 30% !important;
}

.u-size-1of3,
.u-size-2of6,
.u-size-4of12 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 3) !important;
}

.u-size-3of8 {
    flex-basis: auto !important;
    width: 37.5% !important;
}

.u-size-2of5,
.u-size-4of10 {
    flex-basis: auto !important;
    width: 40% !important;
}

.u-size-5of12 {
    flex-basis: auto !important;
    width: calc(100% * 5 / 12) !important;
}

.u-size-1of2,
.u-size-2of4,
.u-size-3of6,
.u-size-4of8,
.u-size-5of10,
.u-size-6of12 {
    flex-basis: auto !important;
    width: 50% !important;
}

.u-size-7of12 {
    flex-basis: auto !important;
    width: calc(100% * 7 / 12) !important;
}

.u-size-3of5,
.u-size-6of10 {
    flex-basis: auto !important;
    width: 60% !important;
}

.u-size-5of8 {
    flex-basis: auto !important;
    width: 62.5% !important;
}

.u-size-2of3,
.u-size-4of6,
.u-size-8of12 {
    flex-basis: auto !important;
    width: calc(100% * 2 / 3) !important;
}

.u-size-7of10 {
    flex-basis: auto !important;
    width: 70% !important;
}

.u-size-3of4,
.u-size-6of8,
.u-size-9of12 {
    flex-basis: auto !important;
    width: 75% !important;
}

.u-size-4of5,
.u-size-8of10 {
    flex-basis: auto !important;
    width: 80% !important;
}

.u-size-5of6,
.u-size-10of12 {
    flex-basis: auto !important;
    width: calc(100% * 5 / 6) !important;
}

.u-size-7of8 {
    flex-basis: auto !important;
    width: 87.5% !important;
}

.u-size-9of10 {
    flex-basis: auto !important;
    width: 90% !important;
}

.u-size-11of12 {
    flex-basis: auto !important;
    width: calc(100% * 11 / 12) !important;
}

/////////////////////////////////////////////
// Intrinsic widths
/////////////////////////////////////////////

// Make an element shrink wrap its content.
.u-size-fit {
    flex-basis: auto !important;
}

// Make an element fill the remaining space.
//
// 1. Be explicit to work around IE10 bug with shorthand flex
//    http://git.io/vllC7
// 2. IE10 ignores previous `flex-basis` value. Setting again here fixes
//    http://git.io/vllMt
.u-size-fill {
    flex: 1 1 0% !important; // 1
    flex-basis: 0% !important; // 2
}

// An alternative method to make an element fill the remaining space.
// Distributes space based on the initial width and height of the element
//
// http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
.u-size-fill-alt {
    flex: 1 1 auto !important;
    flex-basis: auto !important;
}

// Make an element the width of its parent.
.u-size-full {
    width: 100% !important;
}

.u-height-full {
    height: 100% !important;
}
