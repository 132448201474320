// *******************************************
// *******************************************

// Scene icion component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;

// settings/colors
$gray-200: $gray-200;
$all-colors: $all-colors;

// settings/stylistic
$shadow: $shadow;

// component vars
$scene-icon-dimensions: 40;
$scene-label-colors: $all-colors;

.scene-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: rem-calc($scene-icon-dimensions);
    height: rem-calc($scene-icon-dimensions);
    box-shadow: $shadow;
    border: 2px solid $white;
    border-radius: 50%;
    background: $gray-200;
}

.scene-icon__icon {
    margin: 0;
}

// color modifiers
@each $color-key, $color in $scene-label-colors {
    .scene-icon--#{$color-key} {
        background: $color;
    }
}

.scene-icon__loading {
    display: none;
}

// loading modifiers
.scene-icon--loading {
    .scene-icon__icon {
        display: none;
    }

    .scene-icon__loading {
        display: block;
        transform: scale(0.7, 0.7);
    }
}
