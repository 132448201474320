// *******************************************
// *******************************************

// Price component

// *******************************************
// *******************************************

sup,
sub {
    position: relative;
    top: -0.4em;
    font-size: 60%;
    vertical-align: baseline;
}

sub {
    top: 0.4em;
}

code {
    font-family: Consolas, Monaco, monospace;
}
