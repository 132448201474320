// *******************************************
// *******************************************

// Binary color input component

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-touch: $unit-touch;

// Still in use as of 6/23
.binary-color-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: rem-calc($unit-touch * 7);
}

.binary-color-input__input {
    flex: 0 0 rem-calc($unit-touch);
    display: flex;
    justify-content: center;
    align-items: center;
}
