// *******************************************
// *******************************************

// Table component

// *******************************************
// *******************************************

// settings/colors
$gray-200: $gray-200;
$gray-500: $gray-500;
$primary: $primary;
$utility-colors: $utility-colors;

// component vars
$table-row-base-size: 64;
$table-row-40-base-size: 40;
$table-row-32-base-size: 32;
$table-utility-colors: $utility-colors;

.table {
    width: 100%;
    background: $white;
    text-align: left;
    position: relative;
}

.tr {
    border-bottom: 1px solid $gray-200;
}

.actionable {
    transition: background-color 200ms ease;

    &:hover,
    &:focus {
        background: rgba($primary, 0.05) !important;
    }
}

.tr--actionable {
    cursor: pointer;
    transition: background-color 200ms ease;

    &:hover,
    &:focus {
        background: rgba($primary, 0.05) !important;
    }
}

.td,
.th {
    position: relative;
    height: rem-calc($table-row-base-size);
    padding: rem-calc(0 ($unit-default-spacing * 2));
    vertical-align: middle;
}

.th {
    @include sans-bold;
}

.td--legend,
.th--legend {
    padding-left: rem-calc($unit-default-spacing * 6);
}

.td--legend__key {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: rem-calc($unit-default-spacing);
    background: $gray-200;
}

// color modifiers
@each $color-key, $color in $table-utility-colors {
    .td--legend__key--#{$color-key} {
        background: $color;
    }
}

.thead__tr {
    color: $gray-500;
}

.thead__tr--highlight {
    background: $gray-050;
}

.tbody {
    font-size: rem-calc(14);
    background: $white;

    .table--striped & {
        .tr:nth-child(even) {
            background: $gray-050;
        }
    }
}

.tr--actionable__link {
    position: absolute;
    top: 50%;
    right: rem-calc($unit-default-spacing * 2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    transform: translateY(-50%);
}
