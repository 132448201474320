// *******************************************
// *******************************************

// Checkbox input component styles

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-default-spacing: $unit-default-spacing;

.checkbox__input {
    margin: rem-calc(0 $unit-default-spacing 0 0);
}

.checkbox__label {
    justify-content: flex-start;
    font-size: rem-calc(14);
}
