// *******************************************
// *******************************************

// Tag component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-font-spacing: $unit-default-font-spacing;
$unit-default-spacing: $unit-default-spacing;

// settings/colors
$gray-200: $gray-200;
$tag-colors: (
    "gray-600": $gray-600,
    "symbolic-knowledge": $symbolic-knowledge,
    "symbolic-success": $symbolic-success,
    "utility-orange-400": $utility-orange-400,
);

.tag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: rem-calc(0 $unit-default-font-spacing);
    padding: rem-calc($unit-default-font-spacing ($unit-default-spacing * 2));
    background-color: $gray-200;
    color: $gray-800;
}

.tag--round {
    border-radius: 20px;
}

.tag--radius {
    border-radius: 3px;
}

.tag--inline {
    display: inline-flex;
}

.tag__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.tag--outline {
    border: 1px solid $gray-200;
    background: transparent !important;
    color: $gray-200;
}

// tag color modifiers
@each $tag-color-key, $tag-color in $tag-colors {
    .tag--#{$tag-color-key} {
        background-color: $tag-color;
        border-color: $tag-color;
        color: $white;

        &.tag--outline {
            color: $tag-color;
        }
    }

    // modified sizing specifically for status labels
    .tag__label--#{$tag-color-key} {
        width: 75px;
        height: 20px;
        border: 1px solid $tag-color;
        background: transparent;
        color: $tag-color;
        border-radius: 20px;
        padding-top: 0.25px;
        padding-bottom: 0.25px;
        padding-left: 8px;
        padding-right: 8px;
    }
}
