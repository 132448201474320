// *******************************************
// *******************************************

// App layout
// Mobile: Fixed header with hamburger
//         flyout menu
// Desktop: Fixed header and sidebar,
//          main content scrolls

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-touch: $unit-touch;
$unit-default-spacing: $unit-default-spacing;
$unit-sm-edge-space: $unit-sm-edge-space;
$unit-grid-4of12: $unit-grid-4of12;

// setting/colors
$white: $white;
$gray-050: $gray-050;
$gray-200: $gray-200;
$gray-800: $gray-800;

// settings/timing-funcitons
$ease-in-out-quad: $ease-in-out-quad;

// settings/breakpoints
$breakpoint-md: $breakpoint-md;

// component vars
$private-header-height-sm: 80;
$private-header-height-md: 60;
$private-sidebar-z-index: $index-app-header; // settings/z-indexes
$private-slideing-panel-z-index: $index-modal; // settings/z-indexes
$private-sidebar-md-width: 300;

// private container element
.private {
    position: relative;
    min-height: 100vh;
    background: $gray-050;
}

////////////////////////////////////
// App Header
////////////////////////////////////

// contains branding and actions
.private__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem-calc($private-header-height-sm);
    padding: rem-calc(0 $unit-sm-edge-space);
    background: $gray-800;
    color: $white;

    @include min-screen($breakpoint-md) {
        align-items: stretch;
        height: rem-calc($private-header-height-md);
        border-bottom: 1px solid $gray-200;
        background: $white;
        color: $gray-800;
    }
}

.private__header__branding {
    @include min-screen($breakpoint-md) {
        width: rem-calc(
            $private-sidebar-md-width - ($unit-default-spacing * 2)
        );
        margin-right: rem-calc($unit-default-spacing);
        border-right: 1px solid $gray-200;
    }
}

.private__header__branding__logo {
    display: none;

    @include min-screen($breakpoint-md) {
        display: block;
        margin-top: 4px;
    }
}

// mobile visible private section title
.private__header__branding__section-title {
    margin-left: rem-calc($unit-default-spacing);
    font-size: rem-calc(21);

    @include min-screen($breakpoint-md) {
        display: none;
    }
}

// hamburger icon
.private__header__branding__hamburger__bar {
    @include min-screen($breakpoint-md) {
        background: $gray-300;
    }
}

// contains search, alerts, add items
.private__header__actions {
    @include min-screen($breakpoint-md) {
        justify-content: space-between;
        flex: 1 0 auto;
        line-height: 1rem;
    }
}

.private__header__actions__search {
    order: 1;
    padding-left: rem-calc($unit-default-spacing * 2);

    @include min-screen($breakpoint-md) {
        order: 0;
        width: $unit-grid-4of12;
        padding-left: 0;
    }
}

.private__header__actions__tools__tool {
    padding: rem-calc(0 $unit-default-spacing);

    &:last-child {
        padding-right: 0;
    }

    &:first-child {
        padding-left: 0;
    }
}

////////////////////////////////////
// App Sidebar
////////////////////////////////////
.private__sidebar {
    position: fixed;
    top: rem-calc($private-header-height-sm);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $private-sidebar-z-index;
    padding: rem-calc(
        ($unit-default-spacing * 2) 0 ($unit-default-spacing * 2)
            ($unit-default-spacing * 3)
    );
    transform: translateX(-100%);
    overflow: hidden;
    overflow-y: auto;
    background: $gray-800;
    color: $white;
    transition: transform 300ms $ease-in-out-quad;

    @include min-screen($breakpoint-md) {
        top: rem-calc($private-header-height-md);
        right: auto;
        width: rem-calc($private-sidebar-md-width);
        height: calc(100vh - #{rem-calc($private-header-height-md)});
        box-shadow: inset -8px 4px 8px rgba($black, 0.35);
        border-right: 1px solid $white;
    }

    .private--sidebar--visible & {
        transform: translateX(0);
    }
}

.private__sidebar__sm-branding {
    margin-bottom: rem-calc($unit-default-spacing * 3);
    text-align: center;

    @include min-screen($breakpoint-md) {
        display: none;
    }
}

.private__sidebar__nav {
    @include min-screen($breakpoint-md) {
        margin-top: rem-calc($unit-default-spacing * 3);
    }
}

////////////////////////////////////
// App Content Section
////////////////////////////////////
.private__content {
    display: flex;
    flex-flow: column;
    height: calc(100vh - #{rem-calc($private-header-height-sm)});
    overflow: hidden;

    @include min-screen($breakpoint-md) {
        height: calc(100vh - #{rem-calc($private-header-height-md)});
        border-left: solid transparent;
        transition: border-left-width 300ms $ease-in-out-quad;
    }

    .private--sidebar--visible & {
        @include min-screen($breakpoint-md) {
            border-left-width: rem-calc($private-sidebar-md-width);
        }
    }
}

//
.private__content__toolbar {
    flex: 0 0 rem-calc($unit-touch);
    min-height: rem-calc($unit-touch);
    border-bottom: 1px solid $gray-200;
    background: $gray-050;

    @include min-screen($breakpoint-md) {
        padding: rem-calc(0 ($unit-default-spacing * 2));
    }
}

.private__content__main {
    flex: 1 0 auto;
    height: 100%;
    padding-bottom: rem-calc($unit-touch);
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include min-screen($breakpoint-md) {
        padding: rem-calc($unit-default-spacing * 2);
    }
}

////////////////////////////////////
// Sliding panel single entry section
////////////////////////////////////

.private__content__main__sliding-panel {
    position: fixed;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: $private-slideing-panel-z-index;
    margin: 0;
    transform: translate(100%, 0);
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: transform 400ms $ease-in-out-quad;

    @include min-screen($breakpoint-md) {
        top: rem-calc($private-header-height-md - 1);
        bottom: 0;
        left: auto;
        width: 100%;
        max-width: rem-calc(960);
        box-shadow: $shadow-lift;
    }

    .private__content__main--sliding-panel-visible & {
        transform: translate(0, 0);
    }
}
