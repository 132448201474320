// *******************************************
// *******************************************

// Alert component styles

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-touch: $unit-touch;
$unit-default-spacing: $unit-default-spacing;

// settings/colors
$white: $white;
$gray-200: $gray-200;
$gray-700: $gray-700;
$primary: $primary;
$symbolic-success: $symbolic-success;
$symbolic-knowledge: $symbolic-knowledge;
$symbolic-warning: $symbolic-warning;
$symbolic-error: $symbolic-error;

// settings/breakpoings
$breakpoint-md: $breakpoint-md;

// component vars
$alert-default-height: $unit-touch;
$alert-boder-width: 2;
$alert-colors: (
    "primary": $primary,
    "gray-200": $gray-200,
    "gray-700": $gray-700,
    "symbolic-warning": $symbolic-warning,
    "symbolic-error": $symbolic-error,
    "symbolic-success": $symbolic-success,
    "symbolic-knowledge": $symbolic-knowledge,
);

// container element
.alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: rem-calc($alert-default-height);
    margin-bottom: rem-calc($unit-default-spacing * 2);
    padding: rem-calc($unit-default-spacing);
    border: #{$alert-boder-width}px solid $primary;
    background: $primary;
    color: $white;

    @include min-screen($breakpoint-md) {
        padding: rem-calc($unit-default-spacing ($unit-default-spacing * 2));
    }
}

.alert__close {
    &:focus {
        outline: 0;
    }
}

.alert__close__icon {
    margin-left: rem-calc($unit-default-spacing);
    fill: $white;
}

// outline modifier
.alert--outline {
    border-color: $primary;
    background: $white !important;
    color: $primary;

    .alert__close__icon {
        fill: $primary;
    }
}

.alert--animated {
    animation-name: alert-animated;
    animation-duration: 350ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes alert-animated {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.toast--animated {
    animation-name: toast-animated;
    animation-duration: 350ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes toast-animated {
    from {
        transform: translateX(-200%);
    }

    to {
        transform: translateX(0);
    }
}

// alert color modifiers
@each $alert-color-key, $alert-color in $alert-colors {
    .alert--#{$alert-color-key} {
        border-color: $alert-color;
        background: $alert-color;

        // outline modifier
        &.alert--outline {
            border-color: $alert-color;
            background: $white !important;
            color: $alert-color;

            .alert__close__icon {
                fill: $alert-color;
            }
        }
    }
}
