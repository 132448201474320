// *******************************************
// *******************************************

// Slider component

// *******************************************
// *******************************************

// @vars

// Still in use as of 6/23
.slider {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: calc(
        100% - #{rem-calc($unit-touch * 2)}
    ); // account for width of sequential nav
    margin-right: rem-calc($unit-touch);
    margin-left: rem-calc($unit-touch);
    margin-bottom: rem-calc($unit-touch * 2); // account for height of pager nav
}

.slider__slides {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 0 auto;
}

.slider__slide {
    display: none;
}

.slider__slide--current {
    display: block;
}

.slider__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include min-screen($breakpoint-md) {
        width: auto;
    }
}

.slider__nav__sequential {
    width: rem-calc($unit-touch);
    height: rem-calc($unit-touch);

    @include min-screen($breakpoint-md) {
        position: absolute;
        top: 50%;
        z-index: $stack-top;
        transform: translateY(-50%);
    }
}

.slider__nav__sequential--prev {
    left: 25%;
    right: auto;
}

.slider__nav__sequential--next {
    right: 25%;
    left: auto;
}

// for Unit Overview Tiles
.slider__nav__sequential--tile-prev {
    left: -10%;
    right: auto;
}

.slider__nav__sequential--tile-next {
    right: -10%;
    left: auto;
}

.slider__nav__sequential__icon {
    width: rem-calc($unit-touch);
    height: rem-calc($unit-touch);
}

.slider__nav__pager {
    display: none; // hide for smaller screens

    @include min-screen($breakpoint-md) {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: rem-calc($unit-touch);
        line-height: 0;
    }
}

.slider__nav__pager__button {
    position: relative;
    width: rem-calc($unit-touch);
    height: rem-calc($unit-touch);

    @include min-screen($breakpoint-md) {
        width: rem-calc($unit-touch * 0.5);
    }
}

.slider__nav__pager__button__dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: rem-calc($unit-default-spacing * 2);
    height: rem-calc($unit-default-spacing * 2);
    border-radius: 50%;
    background: $gray-200;

    .slider__nav__pager__button--current & {
        background: $primary;
    }
}

/* The slider itself */
.react-slider {
    -webkit-appearance: none;
    -webkit-transition: 0.2s;
    appearance: none;
    background: #eaeaea;
    border-radius: 5px;
    height: 12px;
    margin: 8px 0;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    width: 100%;
}

/* Mouse-over effects */
.react-slider:hover {
    opacity: 1;
}

.react-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #1d2025;
    border-radius: 10px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.react-slider::-moz-range-thumb {
    background: #1d2025;
    border-radius: 10px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}
