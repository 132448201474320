// *******************************************
// *******************************************

// Loading dots component

// *******************************************
// *******************************************

// @vars

// component vars
$loading-dots-size: 24;
$loading-dots-dot-size: 8;

// settings/colors
$gray-800: $gray-800;

// Still in use as of 6/23

.loading-dots {
    position: relative;
    width: rem-calc($loading-dots-size);
    height: rem-calc($loading-dots-size);
}

.loading-dots__dot {
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem-calc($loading-dots-dot-size);
    height: rem-calc($loading-dots-dot-size);
    margin: rem-calc((-$loading-dots-dot-size * 0.5));
    border-radius: 50%;
    background: $gray-800;
    animation: loading-dots 900ms infinite linear;

    &:nth-child(1) {
        left: 0;
        animation-delay: 0;
    }

    &:nth-child(2) {
        animation-delay: 300ms;
    }

    &:nth-child(3) {
        left: 100%;
        animation-delay: 600ms;
    }

    &.loading-dots__dot--primary {
        background: $primary;
    }

    &.loading-dots__dot--error {
        background: $red;
    }

    &.loading-dots__dot--white {
        background: $white;
    }

    &.loading-dots__dot--gray-300 {
        background: $gray-300;
    }

    &.loading-dots__dot--gray-500 {
        background: $gray-500;
    }
}

@keyframes loading-dots {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.5, 1.5);
    }

    75% {
        transform: scale(0.5, 0.5);
    }

    100% {
        transform: scale(1, 1);
    }
}
