// *******************************************
// *******************************************

// Color vars

// *******************************************
// *******************************************

/////////////////////////////////////////////
// Non-semantic color var names
// DON'T USE !!!
/////////////////////////////////////////////

$white: #fff;
$black: #04070b;
$smartrent-primary: #1d2025;
$smartrent-primary-light: #8995ab;
$smartrent-secondary: #2e3237;
$smarthome-primary: #b44891;
$smarthome-primary-light: #bf41a6;
$brand500: #1d2025;
$brand800: #2e3237;
$green: #4caf50;
$orange: #fd7037;
$red: #c2185b;
$blue: #039be5;

/////////////////////////////////////////////
// Semantic color var names
/////////////////////////////////////////////

// grays
$white: $white;
$gray-050: #f3f5f8;
$gray-100: #edeff2;
$gray-200: #ccd1d8;
$gray-300: #bcc5cf;
$gray-400: #95a0ac;
$gray-500: #747b86;
$gray-600: #676f79;
$gray-700: #40464e;
$gray-800: #2e3237;
$gray-900: #1d2025;
$black: $black;
$gray-dark: $gray-800;
$gray-light: $gray-050;

// gray color list
$gray-colors: (
    "white": $white,
    "gray-050": $gray-050,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900,
    "black": $black,
    "gray-dark": $gray-dark,
    "gray-light": $gray-light,
);

// themtatic colors
$primary: $smartrent-primary;
$primary-light: $smartrent-primary-light;
$secondary: $smartrent-secondary;
$alloy-primary: $smarthome-primary;
$alloy-primary-light: $smarthome-primary-light;

// thematic color list
$thematic-colors: (
    "primary": $primary,
    "primary-light": $primary-light,
    "secondary": $secondary,
    "alloy-primary": $alloy-primary,
    "alloy-primary-light": $alloy-primary-light,
);

// symbolic colors
$symbolic-success: $green;
$symbolic-warning: $orange;
$symbolic-error: $red;
$symbolic-knowledge: $blue;

// symbolic color list
$symbolic-colors: (
    "symbolic-success": $symbolic-success,
    "symbolic-warning": $symbolic-warning,
    "symbolic-error": $symbolic-error,
    "symbolic-knowledge": $symbolic-knowledge,
);

// utility colors
$utility-pink-400: #ec407a;
$utility-purple-400: #ab47bc;
$utility-deep-purple-400: #7e57c2;
$utility-indigo-400: #5c6bc0;
$utility-blue-400: #42a5f5;
$utility-light-blue-400: #29b6f6;
$utility-cyan-400: #26c6da;
$utility-teal-400: #26a69a;
$utility-green-400: #66bb6a;
$utility-light-green-400: #9ccc65;
$utility-lime-400: #d4e157;
$utility-yellow-400: #ffee58;
$utility-amber-400: #ffca28;
$utility-orange-400: #ffa726;
$utility-deep-orange-400: #ff7043;
$utility-brown-400: #8d6e63;

$utility-colors: (
    "utility-pink-400": $utility-pink-400,
    "utility-purple-400": $utility-purple-400,
    "utility-deep-purple-400": $utility-deep-purple-400,
    "utility-indigo-400": $utility-indigo-400,
    "utility-blue-400": $utility-blue-400,
    "utility-light-blue-400": $utility-light-blue-400,
    "utility-cyan-400": $utility-cyan-400,
    "utility-teal-400": $utility-teal-400,
    "utility-green-400": $utility-green-400,
    "utility-light-green-400": $utility-light-green-400,
    "utility-lime-400": $utility-lime-400,
    "utility-yellow-400": $utility-yellow-400,
    "utility-amber-400": $utility-amber-400,
    "utility-orange-400": $utility-orange-400,
    "utility-deep-orange-400": $utility-deep-orange-400,
    "utility-brown-400": $utility-brown-400,
);

// ALL colors
$all-colors: map-merge(
    $gray-colors,
    map-merge($thematic-colors, map-merge($symbolic-colors, $utility-colors))
);
