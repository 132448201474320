// *******************************************
// *******************************************

// Content placeholder component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;

// component vars
$placeholder-max-width: 342;
$placeholder-icon-dimensions: 154;

// Still in use as of 6/23
.placeholder {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: rem-calc($placeholder-max-width);
    margin: 0 auto;
}

.placeholder__icon {
    width: rem-calc($placeholder-icon-dimensions);
    height: rem-calc($placeholder-icon-dimensions);
    fill: rgba($primary, 0.3);
}

.placeholder__h1 {
    margin-bottom: rem-calc($unit-default-spacing);
    color: $primary;
    font-size: rem-calc(32);
    line-height: rem-calc(32 * 1.25);
    @include sans-light;
}

.alloy__placeholder__h1 {
    margin-bottom: rem-calc($unit-default-spacing);
    color: $alloy-primary;
    font-size: rem-calc(32);
    line-height: rem-calc(32 * 1.25);
    @include sans-light;
}

.placeholder__button {
    margin-top: rem-calc($unit-default-spacing * 3);
}

.placeholder__instructions {
    text-align: center;
}
