// *******************************************
// *******************************************

// Input component

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-default-font-spacing: $unit-default-font-spacing;
$unit-default-spacing: $unit-default-spacing;
$unit-touch: $unit-touch;

// settings/colors
$gray-200: $gray-200;
$gray-300: $gray-300;
$gray-400: $gray-400;
$gray-600: $gray-600;

// settings/stylistic
$shadow: $shadow;
$shadow-lift: $shadow-lift;
$shadow-lift-light: $shadow-lift-light;

// component vars
$input-validation-colors: (
    "symbolic-error": $symbolic-error,
    "symbolic-warning": $symbolic-warning,
    "symbolic-success": $symbolic-success,
);

.input {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    margin: 0;

    // scss-lint:disable all
    ::-webkit-datetime-edit-text,
    ::-webkit-datetime-edit-month-field,
    ::-webkit-datetime-edit-day-field,
    ::-webkit-datetime-edit-year-field,
    ::-webkit-datetime-edit-hour-field,
    ::-webkit-datetime-edit-minute-field {
        color: $gray-800;
    }
    // scss-lint:enable all
}

.input__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: rem-calc(12);
    color: $gray-600;
    cursor: pointer;
    @include sans-bold;

    &.disabled {
        cursor: not-allowed;

        input {
            cursor: not-allowed;
        }
    }
}

.input__label__light {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: rem-calc(12);
    color: $gray-400;
    cursor: pointer;
    @include sans-bold;
}

.input__label__required {
    font-size: rem-calc(11);
    color: $primary;
    @include sans;
}

.alloy__primary__color {
    color: $alloy-primary;
}

.input__input {
    flex: 1 0 auto; // commented out on 5/9/19, new version of Chrome is shrinking inputs while this is enabled
    height: rem-calc(40);
    padding: rem-calc(0 ($unit-default-spacing * 2));
    box-shadow: $shadow;
    border: 1px solid $gray-200;
    background: $white;
    font-size: 16px; // iOS zoom fix
    color: $gray-800;
    transition: box-shadow 250ms ease, border 250ms ease;

    &:focus {
        box-shadow: $shadow-lift-light;
        outline: none;
        border-color: $gray-300;
    }

    @include placeholder {
        color: $gray-400;
        @include sans-light;
    }

    &.input__input--disabled,
    &[disabled] {
        background: $gray-100;
        color: $gray-300;
    }
}

// Used for inputs associated with dynamic table components
// De-emphasizes the input in the UI as compared to .input__input
.input__table-input {
    width: 100%;
    height: 1.5rem;
    padding: 4px;
    border: 1px solid $gray-200;
    background: $white;
    font-size: 14px;
    color: $gray-800;

    &:focus {
        box-shadow: $shadow;
        outline: none;
        border-color: $gray-300;
    }

    @include placeholder {
        color: $gray-400;
        @include sans-light;
    }
}

// 480px because most mobile devices are 480px or smaller (http://javascriptkit.com/dhtmltutors/cssmediaqueries2.shtml)
// Does not work with our breakpoints.scss file
@include max-screen("480px") {
    .input__table-input {
        margin-bottom: 8px;
    }
}

.input__input--textarea {
    min-height: rem-calc(150);
    padding-top: rem-calc($unit-default-font-spacing * 4);
    padding-bottom: rem-calc($unit-default-font-spacing * 4);
}

.input__validation {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin: rem-calc(0 $unit-default-font-spacing * 3);
    font-size: rem-calc(11);
    line-height: rem-calc(12);
}

// validation modifiers
@each $input-validation-color-key,
    $input-validation-color in $input-validation-colors
{
    .input--validation-#{$input-validation-color-key} {
        .input__label,
        .input__validation {
            color: $input-validation-color;
        }

        .input__input {
            border-color: $input-validation-color;
            color: $input-validation-color;

            @include placeholder {
                color: $input-validation-color;
            }
        }
    }
}

// size modifiers
.input--32px-base {
    .input__input {
        height: rem-calc(32);
        padding: rem-calc(0 $unit-default-spacing);
        font-size: rem-calc(14);
        line-height: rem-calc(32);
    }
}
