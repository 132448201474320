// *******************************************
// *******************************************

// Public layout
// Login, register, reset password, etc.

// *******************************************
// *******************************************

// @vars
// settings/z-indexes
$stack-bot: $stack-bot;
$stack-sub: $stack-sub;

// settings/breakpoints
$breakpoint-md: $breakpoint-md;

// setting/colors
$secondary: $secondary;
$white: $white;
$gray-050: $gray-050;
$gray-200: $gray-200;
$gray-800: $gray-800;

// settings/stylistic
$gradient-image-overlay: $gradient-image-overlay;

// container element
.public {
    position: relative;
    z-index: $stack-bot;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: $secondary;

    @include min-screen($breakpoint-md) {
        background: $secondary url("/images/public-background.jpg") center
            no-repeat;
        background-size: cover;
    }

    // image overlay gradient
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $stack-sub;
        background: rgba($gray-900, 70%);

        @include min-screen($breakpoint-md) {
            opacity: 0.9;
        }
    }

    .h2 {
        color: #1d2025;
    }
}

// header container element
.public__header {
    margin-bottom: rem-calc($unit-default-spacing * 4);
    text-align: center;
}

.public__header__branding {
    display: block;
    width: rem-calc(320);
    margin-bottom: rem-calc($unit-default-spacing * 2);
}

.public__header__branding--small {
    display: block;
    width: rem-calc(160);
    margin-bottom: rem-calc($unit-default-spacing * 2);
}

.public__content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: rem-calc(0 ($unit-default-spacing * 2));

    @include min-screen($breakpoint-md) {
        min-width: rem-calc(400);
    }
}

.public__content__flash {
    width: 100%;
    max-width: rem-calc(320);

    .alert {
        margin-bottom: rem-calc($unit-default-spacing * 4);
    }
}
