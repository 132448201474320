// *******************************************
// *******************************************

// Flex utilities

// *******************************************
// *******************************************

// @vars
$u-flex-breakpoints: $breakpoints !default; // imported from settings/breakpoints

/////////////////////////////////////////////
// Applies to flex container
/////////////////////////////////////////////

// Container
.u-flex {
    display: flex !important;
}

// breakpoints
@each $u-breakpoint-key, $u-display-breakpoint in $u-display-breakpoints {
    @include min-screen(#{$u-display-breakpoint}) {
        .u-flex--#{$u-breakpoint-key} {
            display: flex !important;
        }
    }
}

.u-flex-inline {
    display: inline-flex !important;
}

// breakpoints
@each $u-breakpoint-key, $u-display-breakpoint in $u-display-breakpoints {
    @include min-screen(#{$u-display-breakpoint}) {
        .u-flex-inline--#{$u-breakpoint-key} {
            display: inline-flex !important;
        }
    }
}

// Direction: row

.u-flex-row {
    flex-direction: row !important;
}

.u-flex-rowreverse {
    flex-direction: row-reverse !important;
}

// Direction: column

.u-flex-col {
    flex-direction: column !important;
}

.u-flex-col-reverse {
    flex-direction: column-reverse !important;
}

// Wrap

.u-flex-wrap {
    flex-wrap: wrap !important;
}

.u-flex-nowrap {
    flex-wrap: nowrap !important;
}

.u-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

// Align items along the main axis of the current line of the flex container

.u-flex-justify-start {
    justify-content: flex-start !important;
}

.u-flex-justify-end {
    justify-content: flex-end !important;
}

.u-flex-justify-center {
    justify-content: center !important;
}

.u-flex-justify-between {
    justify-content: space-between !important;
}

.u-flex-justify-around {
    justify-content: space-around !important;
}

// Align items in the cross axis of the current line of the flex container
// Similar to `justify-content` but in the perpendicular direction

.u-flex-align-items-start {
    align-items: flex-start !important;
}

.u-flex-align-items-end {
    align-items: flex-end !important;
}

.u-flex-align-items-center {
    align-items: center !important;
}

.u-flex-align-items-stretch {
    align-items: stretch !important;
}

.u-flex-align-items-baseline {
    align-items: baseline !important;
}

// Aligns items within the flex container when there is extra
// space in the cross-axis
//
// Has no effect when there is only one line of flex items.

.u-flex-align-content-start {
    align-content: flex-start !important;
}

.u-flex-align-content-end {
    align-content: flex-end !important;
}

.u-flex-align-content-center {
    align-content: center !important;
}

.u-flex-align-content-stretch {
    align-content: stretch !important;
}

.u-flex-align-content-between {
    align-content: space-between !important;
}

.u-flex-align-content-around {
    align-content: space-around !important;
}

// 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7

.u-flex > *,
.u-flex-inline > * {
    flex-shrink: 1; // 1
}

/////////////////////////////////////////////
// Applies to flex items
/////////////////////////////////////////////

// Override default alignment of single item when specified by `align-items`

.u-flex-align-self-start {
    align-self: flex-start !important;
}

.u-flex-align-self-end {
    align-self: flex-end !important;
}

.u-flex-align-self-center {
    align-self: center !important;
}

.u-flex-align-self-stretch {
    align-self: stretch !important;
}

.u-flex-align-self-baseline {
    align-self: baseline !important;
}

.u-flex-align-self-auto {
    align-self: auto !important;
}

// Change order without editing underlying HTML

.u-flex-order-first {
    order: -1 !important;
}

.u-flex-order-last {
    order: 1 !important;
}

.u-flex-order-none {
    order: 0 !important;
}

// Specify the flex grow factor, which determines how much the flex item will
// grow relative to the rest of the flex items in the flex container.
//
// Supports 1-5 proportions
//
// 1. Provide all values to avoid IE10 bug with shorthand flex
//    - http://git.io/vllC7
//
//    Use `0%` to avoid bug in IE10/11 with unitless flex basis. Using this
//    instead of `auto` as this matches what the default would be with `flex`
//    shorthand - http://git.io/vllWx

.u-flex-basis-reset {
    flex: 0 0 auto !important;
}

.u-flex-grow1 {
    flex: 1 1 0% !important; // 1
}

.u-flex-grow2 {
    flex: 2 1 0% !important;
}

.u-flex-grow3 {
    flex: 3 1 0% !important;
}

.u-flex-grow4 {
    flex: 4 1 0% !important;
}

.u-flex-grow5 {
    flex: 5 1 0% !important;
}

// Aligning with `auto` margins
// http://www.w3.org/TR/css-flexbox-1/#auto-margins

.u-flex-expand {
    margin: auto !important;
}

.u-flex-expand-left {
    margin-left: auto !important;
}

.u-flex-expand-right {
    margin-right: auto !important;
}

.u-flex-expand-top {
    margin-top: auto !important;
}

.u-flex-expand-bottom {
    margin-bottom: auto !important;
}
