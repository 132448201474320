// *******************************************
// *******************************************

// Button component styles

// *******************************************
// *******************************************

// @vars
$button-default-height: $unit-touch; // settings/layout-units
$button-max-width: $unit-max-line-length; // settings/layout-units
$button-edge-space: $unit-default-spacing * 3; // settings/layout-units
$button-shadows: $shadows; // settings/stylistic
$button-transition: background-color 250ms ease;
$button-outline-transition: border 250ms ease, color 250ms ease;
$button-radius: 50px;
$button-sizes: (
    "default": (
        "size": $unit-touch,
        "font-size": 14,
        "edge-space": $unit-default-spacing,
    ),
    "md": (
        "size": 32,
        "font-size": 14,
        "edge-space": $unit-default-spacing - 1,
    ),
    "sm": (
        "size": 24,
        "font-size": 13,
        "edge-space": $unit-default-spacing - 1,
    ),
);

$button-base-width: 138;
$button-base-height: 40;

// button defaults
.button {
    position: relative;
    display: inline-block;
    height: rem-calc($button-base-height);
    min-width: rem-calc($button-base-width);
    margin: rem-calc(0 0 $button-edge-space 0);
    padding: rem-calc(0 $button-edge-space);
    overflow: hidden;
    border-radius: rem-calc(3);
    background: $primary;
    font-size: rem-calc(14);
    color: $white;
    text-align: center;
    text-decoration: none;
    transition: $button-transition;
    @include sans-bold;

    &:hover,
    &:focus {
        background: $primary-light;
    }
}

.alloy__button {
    background: $alloy-primary;

    &:hover,
    &:focus {
        background: $alloy-primary-light;
    }
}

.button--equal-width {
    width: rem-calc($button-base-width);
    min-width: 0;
    padding-right: 0;
    padding-left: 0;
}

// button flexbox layout
// direct child of button element
.button__layout {
    position: relative;
    z-index: $stack-top;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
    white-space: nowrap;
}

// outline style modifier
.button--outline {
    border: 1px solid $primary;
    background: $white;
    color: $primary;
    transition: $button-outline-transition;

    &::before {
        display: none;
    }

    &:hover,
    &:focus {
        border-color: $primary-light;
        background: $white;
        color: $primary-light;
    }

    .icon {
        filter: none;
    }
}

.button--no-margin {
    margin: 0;
}

// color modifiers
.button--gray-200 {
    background: $gray-200;
    color: $gray-700;

    &:hover,
    &:focus {
        background: $gray-300;
        color: $gray-700;
    }

    &.button--disabled,
    &[disabled] {
        background: $gray-100;
        color: $gray-300;
    }
}

.button--gray-400 {
    background: $gray-400;
    color: $gray-600;

    &:hover,
    &:focus {
        background: $gray-300;
        color: $gray-600;
    }

    &.button--disabled,
    &[disabled] {
        background: $gray-100;
        color: $gray-300;
    }

    &.button--outline {
        border-color: $gray-600;
        background: $white;
        transition: $button-outline-transition;
    }
}

.button--gray-700 {
    background: $gray-700;
    color: $gray-050;

    &:hover,
    &:focus {
        background: $gray-800;
        color: $white;
    }

    &.button--disabled,
    &[disabled] {
        background: $gray-100;
        color: $gray-300;
    }
}

.button--orange-400 {
    background: $utility-orange-400;
    color: $white;

    &:hover,
    &:focus {
        background: tint($utility-orange-400, 20%);
        color: $white;
    }

    &.button--disabled,
    &[disabled] {
        background: $utility-orange-400;
        color: $white;
    }
}

.button--symbolic-error {
    background: $symbolic-error;
    color: $white;

    &:hover,
    &:focus {
        background: tint($symbolic-error, 20%);
        color: $white;
    }

    &.button--disabled,
    &[disabled] {
        background: $symbolic-error;
        color: $white;
    }
}

.button--primary-error {
    background-color: rgb(244, 67, 54);
    transition: all 0.3s;
    color: $white;

    &:hover,
    &:focus {
        background: tint(rgb(244, 67, 54), 20%);
    }
}

// disabled modifier
.button--disabled,
.button[disabled] {
    border-color: $gray-100;
    background: transparent !important;
    color: $gray-300;
    cursor: not-allowed;
}

// expand modifier
.button--expand {
    display: block;
    width: 100%;
    max-width: none;
}

// shrink modifier
.button--shrink {
    min-width: 0;
}

// removes action border
.button--no-action {
    outline: none;
    cursor: default;
}

.button--no-background {
    background: none;
    &:hover,
    &:focus {
        background: none;
        color: none;
    }
}

.button__pagination {
    height: auto;
    min-width: auto;
    padding: 16px;
    margin: 4px;
}

// .button--sm .button--default, .button--md
@each $button-size-key, $button-size in $button-sizes {
    .button--#{$button-size-key} {
        font-size: rem-calc(map-get($button-size, "font-size")) !important;
        height: rem-calc(map-get($button-size, "size")) !important;
        margin: rem-calc(0 0 map-get($button-size, "edge-space") 0) !important;
    }
}
