// *******************************************
// *******************************************

// Device pairing component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-font-spacing: $unit-default-font-spacing;
$unit-default-spacing: $unit-default-spacing;

// component vars
$device-pairing-dimensions: 100;
$device-pairing-circle-dimensions: $device-pairing-dimensions * 0.25;

// Still in use as of 6/23

.device-pairing__container {
    position: relative;
    max-width: rem-calc($device-pairing-dimensions);
    height: rem-calc($device-pairing-dimensions * 0.85);
}

.device-pairing__img {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $stack-top;
    transform: translate(-50%, -50%);
    width: 60%;
}

.device-pairing__pulse {
    position: relative;
    top: 45%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $stack-bot;
}

.device-pairing__pulse__circle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: rem-calc($device-pairing-circle-dimensions);
    height: rem-calc($device-pairing-circle-dimensions);
    margin: auto;
    transform: scale(0.5);
    transform-origin: center center;
    border-radius: rem-calc($device-pairing-circle-dimensions * 0.5);
    background: $primary;
    animation: device-pairing-pulse 3s linear infinite;

    &:nth-child(2) {
        animation-delay: 0.5s;
    }

    &:nth-child(3) {
        animation-delay: 1s;
    }

    &:nth-child(4) {
        animation-delay: 1.5s;
    }

    .device-pairing--exclusion & {
        background: $symbolic-warning;
    }

    .device-pairing--success & {
        background: $symbolic-success;
    }

    .device-pairing--failed & {
        display: none;
    }

    .device-pairing--select & {
        display: none;
    }
}

@keyframes device-pairing-pulse {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        opacity: 0.1;
    }

    70% {
        opacity: 0.09;
    }

    100% {
        transform: scale(5);
        opacity: 0;
    }
}

.device-pairing__cancel-button {
    display: none;

    .device-pairing--inclusion & {
        display: inline;
    }

    .device-pairing--exclusion & {
        display: inline;
    }

    .device-pairing--failed & {
        display: inline;
    }
}

.device-pairing__retry-button {
    display: none;

    .device-pairing--failed & {
        display: inline;
    }
}

.device-pairing__include-button {
    display: none;

    .device-pairing--select & {
        display: inline;
    }
}

.device-pairing__exclude-button {
    display: none;

    .device-pairing--select & {
        display: inline;
    }
}
