// *******************************************
// *******************************************

// App Header component

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-app-header-height: $unit-app-header-height;

// component containter
.app-header {
    display: flex;
    align-items: center;
    background: $gray-800;

    @include min-screen($breakpoint-md) {
        justify-content: space-between;
    }
}

// main content components
// IE: brandmark, nav
.app-header__main {
    height: rem-calc($unit-app-header-height);

    @include min-screen($breakpoint-md) {
        display: flex;
        align-items: center;
    }
}

// central station 9 squares
.app-header__main__cs {
    display: none;

    @include min-screen($breakpoint-md) {
        display: block;
        height: rem-calc($unit-default-spacing * 5);
        margin-left: rem-calc($unit-default-spacing * 2);
        margin-top: rem-calc($unit-default-spacing * 2);
    }
}

// brandmark
.app-header__main__brandmark {
    display: none;

    @include min-screen($breakpoint-md) {
        display: block;
        margin-left: rem-calc($unit-default-spacing);
    }
}

.app-header__main__brandmark__img {
    @include min-screen($breakpoint-md) {
        width: rem-calc(140);
        height: auto;
    }
}

// navicon
.app-nav__navicon {
    // hide on large screens
    @include min-screen($breakpoint-md) {
        display: none;
    }
}

.app-nav__navicon__layout {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: rem-calc($unit-app-header-height);
    height: rem-calc($unit-app-header-height);
}

.app-nav__navicon__icon {
    fill: $primary;
}

// sub content components
// IE: search button
.app-header__sub {
    flex: 1 0 auto;

    @include min-screen($breakpoint-md) {
        flex: 0 0 rem-calc(380);
    }
}
