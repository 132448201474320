// *******************************************
// *******************************************

// Typography components

// *******************************************
// *******************************************

/////////////////////////////////////////////
// Headers
// @Note: line height = font-size * round(1.3)
/////////////////////////////////////////////

.h1 {
    @include sans-bold;
    font-size: rem-calc(48);
    line-height: rem-calc(62);
}

.h2 {
    @include sans-bold;
    font-size: rem-calc(30);
    line-height: rem-calc(40);
}

.h3 {
    @include sans-bold;
    font-size: rem-calc(21);
    line-height: rem-calc(27);
}

.h4 {
    @include sans-bold;
    font-size: rem-calc(18);
    line-height: rem-calc(24);
}

.h5 {
    @include sans-bold;
    font-size: rem-calc(16);
    line-height: rem-calc(21);
}

.h6 {
    @include sans-bold;
    font-size: rem-calc(12);
    line-height: rem-calc(15);
}

/////////////////////////////////////////////
// Lists
/////////////////////////////////////////////
.ul,
.ol {
    margin: rem-calc(0 0 18 21);
    font-size: rem-calc(18);
    line-height: rem-calc(27);
}

.ul {
    list-style-type: disc;
}

.ol {
    list-style-type: decimal;
}

/////////////////////////////////////////////
// Other typography elements
/////////////////////////////////////////////
.p {
    margin-bottom: rem-calc(18);
    font-size: rem-calc(18);
    line-height: rem-calc(27);
}

.p--small {
    font-size: rem-calc(16);
    line-height: rem-calc(21);
}

.strong,
.b {
    @include sans-bold;
    font-weight: bold;
}

.em,
.i {
    font-style: italic;
}

.small {
    font-size: 70%;
}

.a {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
    transition: color 200ms ease;

    &:hover {
        color: $secondary;
        text-decoration: none;
    }
}

// scss-lint:disable PlaceholderInExtend
// typgraphy wrapping element
.typography {
    h1 {
        @extend .h1;
    }

    h2 {
        @extend .h2;
    }

    h3 {
        @extend .h3;
    }

    h4 {
        @extend .h4;
    }

    h5 {
        @extend .h5;
    }

    h6 {
        @extend .h6;
    }

    ul {
        @extend .ul;
    }

    ol {
        @extend .ol;
    }

    p {
        @extend .p;
    }

    strong {
        @extend .strong;
    }

    b {
        @extend .i;
    }

    em {
        @extend .em;
    }

    i {
        @extend .i;
    }

    small {
        @extend .small;
    }

    a {
        @extend .a;
    }
}
