// *******************************************
// *******************************************

// Modal component

// *******************************************
// *******************************************

.modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $index-modal;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background-color: rgba($black, 0.5);
}
