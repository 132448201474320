// *******************************************
// *******************************************

// Error page layout

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;

.page--error {
    padding: rem-calc($unit-default-spacing * 4);
    text-align: center;

    @include min-screen($breakpoint-md) {
        display: flex;
        flex-flow: column;
        justify-content: center !important;
        align-items: center !important;
        text-align: center;
    }
}

.page--error__content {
    max-width: rem-calc(524);
    margin: 0 auto;
}

// 500 page
.page--error--500 {
    background: $primary-light !important;
}
