// **************************************************
// **************************************************

// App-wide list of indexes

// **************************************************
// **************************************************

// default stacking orer indexes
$stack-sub: -1;
$stack-bot: 0;
$stack-mid: 2;
$stack-top: 3;

// layout/component indexes
$index-app-header: 22;
$index-app-notification: 10;
$index-app-header: 20;
$index-modal: 21;
$index-app-nav-sm-index: 22;
$index-fab: 10;
$index-app-content: 1;
$index-date-picker: 501;
