// *******************************************
// *******************************************

// Flexbox grid mixins
// inspired by https://github.com/kristoferjoseph/flexboxgrid
// Site - http://flexboxgrid.com/

// *******************************************
// *******************************************

// @vars
// imported vars
$breakpoint-sm: $breakpoint-sm; // settings/breakpoints
$breakpoint-md: $breakpoint-md; // settings/breakpoints

// Flexbox Grid Settings
$column-count: $unit-grid-col-count;
$column-selector: ".grid__";
$offset-modifier: "offset";

$gutter-width: rem-calc($unit-grid-gutter); // 21px
$outer-margin: 0;
$gutter-compensation: (($gutter-width * 0.5) * -1);
$half-gutter-width: ($gutter-width * 0.5);

// breakpoint values come from settings/breakpoints
$viewport-sm: $breakpoint-sm;
$viewport-md: $breakpoint-md;

$sm: "sm";
$md: "md";

$container-sm: "#{$sm} + $gutter-width";
$container-md: "#{$md} + $gutter-width";

// Removed to match 'size-x' syntax
$block-delimiter: "";
$element-delimiter: "-";
$modifier-delimiter: "--";

$default-query: $viewport-sm;
$queries: (
    $sm: $viewport-sm,
    $md: $viewport-md,
);

$column-properties: "flex-basis", "max-width";
$offset-properties: "margin-left";

// Still in use as of 6/23

.grid {
    @include row();
}

%column {
    @include column();
}

@include columns() {
    @extend %column;
}

@include offsets() {
    @extend %column;
}

@include breakpoints(".start") {
    justify-content: flex-start;
}

@include breakpoints(".center") {
    justify-content: center;
}

@include breakpoints(".end") {
    justify-content: flex-end;
}

@include breakpoints(".top") {
    align-items: flex-start;
}

@include breakpoints(".middle") {
    align-items: center;
}

@include breakpoints(".bottom") {
    align-items: flex-end;
}

@include breakpoints(".around") {
    justify-content: space-around;
}

@include breakpoints(".between") {
    justify-content: space-between;
}

@include breakpoints(".first") {
    order: -1;
}

@include breakpoints(".last") {
    order: 1;
}

@include breakpoints(".reverse") {
    flex-direction: row-reverse;
}
