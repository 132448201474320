// *******************************************
// *******************************************

// Generic page layout

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;
$unit-app-header-height: $unit-app-header-height;
$unit-app-content-sidebar-md-width: $unit-app-content-sidebar-md-width;
$unit-sidebar-header-md-height: $unit-sidebar-header-md-height;

// settings/breakpoints
$breakpoint-md: $breakpoint-md;

// component vars
$page-width-large: $unit-default-content-width;
$page-width-medium: 960;
$page-width-small: 720;
$page-width-micro: 400;
$page-width-subnav: 620;

.page {
    flex: 1 0 100%;
    width: 100%;

    @include min-screen($breakpoint-md) {
        position: relative;
        z-index: $stack-bot;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        padding-top: rem-calc($unit-sidebar-header-md-height);
        padding-bottom: rem-calc($unit-touch * 2);
    }

    > * {
        width: 100%;
    }
}

.page--map {
    padding-bottom: 0;
}

.page--large-width {
    flex: 1 0 rem-calc($page-width-large);
    max-width: rem-calc($page-width-large);
}

// medium width modifier
.page--medium-width {
    flex: 1 0 rem-calc($page-width-medium);
    max-width: rem-calc($page-width-medium);
}

// small width modifier
.page--small-width {
    flex: 1 0 rem-calc($page-width-small);
    max-width: rem-calc($page-width-small);
}

// small width modifier
.page--micro-width {
    flex: 1 0 rem-calc($page-width-micro);
    max-width: rem-calc($page-width-micro);
    max-width: rem-calc($page-width-micro);
}

// full width modifier
// full width modifier
.page--full-width {
    max-width: none;
}

.page__toolbar {
    @include min-screen($breakpoint-md) {
        position: fixed;
        top: rem-calc($unit-app-header-height);
        right: 0;
        z-index: $stack-top;
        width: calc(100% - #{rem-calc($unit-app-content-sidebar-md-width)});

        &.page__toolbar--bottom {
            top: auto;
            bottom: 0;
        }
    }

    // if flash notification is
    // being displayed
    .page--flash & {
        @include min-screen($breakpoint-md) {
            top: rem-calc($unit-app-header-height * 2);
        }
    }
}

// modifier for pages with no
// content sidebar
.page__toolbar--expand {
    @include min-screen($breakpoint-md) {
        width: 100%;
    }
}

.page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem-calc($unit-default-spacing * 2);
    padding: rem-calc($unit-default-spacing);
    border-bottom: 1px solid $gray-200;
    background: $gray-050;

    @include min-screen($breakpoint-md) {
        padding: rem-calc($unit-default-spacing * 3);
    }
}

.page__header--no-border {
    border-bottom: 0;
}

.page__header__title {
    font-size: rem-calc(16);
    @include sans-bold;

    @include min-screen($breakpoint-md) {
        margin-bottom: rem-calc($unit-default-spacing);
        font-size: rem-calc(32);
    }
}

.page__content {
    padding: rem-calc(0 $unit-default-spacing);

    @include min-screen($breakpoint-md) {
        display: flex;
        flex-flow: column;
        flex: 1 0 100%;
        width: 100%;
        padding: rem-calc(
            ($unit-default-spacing * 2) ($unit-default-spacing * 3)
        );
    }
}

.page__content--centered {
    align-items: center;
}

.page__list {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    @include min-screen($breakpoint-md) {
        overflow: visible;
    }
}

// page section headers
.page__section-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.page__section-header__h1 {
    font-size: rem-calc(14);
    @include sans-bold;
}
