// *******************************************
// *******************************************

// Scene label component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;

// settings/z-indexes
$stack-top: $stack-top;

// settings/stylistic
$shadow: $shadow;

// settings/colors
$utility-colors: $utility-colors;

// component vars
$scene-label-width: 180;
$scene-label-height: 56;
$scene-label-radius: 7;
$scene-label-icon-dimension: 24;
$scene-label-colors: $all-colors;

.scene-label {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: rem-calc($scene-label-width);
    height: rem-calc($scene-label-height);
    padding: rem-calc(0 ($unit-default-spacing * 2));
    box-shadow: $shadow;
    border-radius: rem-calc($scene-label-radius);
    background: $gray-200;
    color: $white;
    cursor: pointer;
    transition: background-color 200ms ease, box-shadow 200ms ease;

    &:hover,
    &:focus {
        box-shadow: $shadow-lift-light;
        background: shade($gray-200, 5%);
    }

    &.scene-label--loading {
        box-shadow: none;
        cursor: progress;
    }
}

// color modifiers
@each $color-key, $color in $scene-label-colors {
    .scene-label--#{$color-key} {
        background: $color;

        &:hover,
        &:focus {
            background: shade($color, 5%);
        }

        &.scene-label--loading {
            background: tint($color, 20%);
        }
    }
}

.scene-label__detail {
    display: flex;
    align-items: center;
}

.scene-label__detail__icon {
    position: relative;
    width: rem-calc($scene-label-icon-dimension);
    height: rem-calc($scene-label-icon-dimension);
    margin-right: rem-calc($unit-default-spacing);
}

.scene-label__detail__icon__default {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);

    .scene-label--loading & {
        display: none;
    }
}

.scene-label__detail__icon__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);

    .scene-label--loading & {
        display: block;
    }
}

.scene-label__detail__name {
    font-size: rem-calc(12);
    width: rem-calc(100);
    max-width: rem-calc(100);
}
