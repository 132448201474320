// *******************************************
// *******************************************

// List component

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-default-font-spacing: $unit-default-font-spacing;
$unit-default-spacing: $unit-default-spacing;

// settings/breakpoint
$breakpoint-md: $breakpoint-md;

// settings/colors
$white: $white;
$gray-200: $gray-200;
$gray-400: $gray-400;
$black: $black;

// settings/timing-functions
$ease-in-out-quad: $ease-in-out-quad;

// component vars
$list-base-size: 64;
$list-40-base-size: 40;
$list-32-base-size: 32;

.list {
    background: $white;
    font-size: rem-calc(14);
}

.list--bordered {
    border: 1px solid $gray-200;

    .list__item:last-child {
        border-bottom: 0;
    }
}

.list--border-top {
    border-top: 1px solid $gray-200;
}

.list__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: rem-calc($list-base-size);
    padding: rem-calc($unit-default-spacing * 2);
    border-bottom: 1px solid $gray-200;
    background: $white;

    @include min-screen($breakpoint-md) {
        align-items: center;
    }
}

.list__item--gray-050 {
    background: $gray-050;
}

.list__item--32px-base {
    min-height: rem-calc(32);
    padding: rem-calc($unit-default-spacing);
}

.list__item--current {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: rem-calc($unit-default-font-spacing);
        background: $primary;
    }
}

.list__item--header {
    background: $white !important;
    font-size: rem-calc($unit-base-font-size);
    color: $gray-500;
    @include sans-bold;
}

.list__item--sub-header {
    min-height: rem-calc($unit-default-spacing * 3);
    padding-top: rem-calc($unit-default-spacing);
    padding-bottom: rem-calc($unit-default-spacing);
    border-top: 2px solid $gray-100;
    border-bottom: 2px solid $gray-100;
    background: $gray-050;
}

.list--striped {
    .list__item:nth-child(odd) {
        background: $gray-050;
    }
}

.list__item--actionable {
    transition: background-color 200ms ease;

    &:hover,
    &:focus {
        background: rgba($primary, 0.05) !important;
    }
}

// item color modifiers
.list__item--symbolic-warning {
    background: rgba($symbolic-warning, 0.1);

    &.list__item--actionable {
        &:hover,
        &:focus {
            background: rgba($symbolic-warning, 0.2) !important;
        }
    }
}

.list__grid {
    @include min-screen($breakpoint-md) {
        flex: 1 0 auto;
    }
}

.list__item__row-link {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    min-height: rem-calc($list-base-size);
    margin: rem-calc(-$unit-default-spacing * 2);
    padding: rem-calc($unit-default-spacing * 2);
    color: $gray-700;
    text-decoration: none;
}

.list__item__action-icon {
    position: absolute;
    top: rem-calc($unit-default-spacing * 2);
    right: rem-calc($unit-default-spacing);

    @include min-screen($breakpoint-md) {
        position: relative;
        top: auto;
        right: auto;
    }
}
