// *******************************************
// *******************************************

// Header search component

// *******************************************
// *******************************************

// @vars

.header-search {
    display: flex;
    align-items: center;
}

.header-search__icon {
    margin: 0;
    fill: $white;

    @include min-screen($breakpoint-md) {
        width: rem-calc(16);
        height: rem-calc(16);
        margin-right: rem-calc($unit-default-spacing);
        margin-left: rem-calc($unit-default-spacing);
        fill: $gray-300;
    }
}

.header-search__form {
    display: none;

    @include min-screen($breakpoint-md) {
        display: block;
        flex: 1 0 auto;
    }
}

.header-search__form__input {
    width: 100%;
    border: 0;
    background: transparent;
    font-size: rem-calc(16);
    color: $gray-800;

    @include placeholder {
        color: $gray-400;
        @include sans-light;
    }

    &:focus {
        outline: none;
    }
}
