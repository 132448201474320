// *******************************************
// *******************************************

// Stylistic utilites

// *******************************************
// *******************************************

// @vars
// settings/stylistic
$shadows: $shadows;

@each $shadow-key, $shadow-value in $shadows {
    .u-#{$shadow-key} {
        box-shadow: $shadow-value !important;
    }
}
