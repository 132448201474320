// *******************************************
// *******************************************

// Overlay component

// *******************************************
// *******************************************

// @vars
// settings/colors
$white: $white;
$black: $black;

// settings/layout-units
$unit-default-spacing: $unit-default-spacing;

.overlay {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: rem-calc($unit-default-spacing);
    overflow: auto;
}

.overlay--black {
    background: rgba($black, 0.7);
}

.overlay--white {
    background: rgba($white, 0.7);
}
