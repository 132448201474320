// *******************************************
// *******************************************

// Tabs navigation components

// *******************************************
// *******************************************

// @vars

// settings/layout-units
$unit-touch: $unit-touch;
$unit-default-spacing: $unit-default-spacing;

.tabs {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    background: $gray-050;

    // material design tabs modifier
    &.tabs--material {
        border: 0;
        background: transparent;
    }
}

// tabs alignment modifier
.tabs--center {
    justify-content: center;
}

.tabs--right {
    justify-content: flex-end;
}

.tabs__tab {
    margin-bottom: -1px;
    border-bottom: 1px solid $gray-200;
    border-left: 1px solid $gray-200;
    background: $gray-050;

    &:last-child {
        border-right: 1px solid $gray-200;
    }

    // material design tabs modifier
    .tabs--material & {
        border: 0;
        background: transparent;
    }
}

.tabs__tab--current {
    border-bottom-color: $white;
    background: $white;

    // material design tabs modifier
    .tabs--material & {
        background: transparent;
    }
}

.tabs__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: rem-calc($unit-touch);
    min-height: rem-calc($unit-touch);
    padding: rem-calc(0 $unit-default-spacing);
    color: $gray-700;
    text-decoration: none;

    // change made on 5/1/22 to match React styles
    font-weight: 600;

    .tabs__tab--current & {
        color: $gray-900;

        // material design tabs modifier
        .tabs--material & {
            color: $primary;

            // scss-lint:disable NestingDepth
            &::before {
                opacity: 1;
            }
            // scss-lint:enable NestingDepth
        }
    }

    // material design tabs modifier
    .tabs--material & {
        position: relative;
        color: $gray-600;

        &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: rem-calc($unit-default-font-spacing);
            opacity: 0;
            background: $primary;
        }
    }
}

.alloy__tabs__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: rem-calc($unit-touch);
    min-height: rem-calc($unit-touch);
    padding: rem-calc(0 $unit-default-spacing);
    color: $gray-700;
    text-decoration: none;

    .tabs__tab--current & {
        color: $gray-900;

        // material design tabs modifier
        .tabs--material & {
            color: $alloy-primary;

            // scss-lint:disable NestingDepth
            &::before {
                opacity: 1;
            }
            // scss-lint:enable NestingDepth
        }
    }

    // material design tabs modifier
    .tabs--material & {
        position: relative;
        color: $gray-600;

        &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: rem-calc($unit-default-font-spacing);
            opacity: 0;
            background: $alloy-primary;
        }
    }
}
