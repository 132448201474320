// *******************************************
// *******************************************

// Binary input component

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-default-font-spacing: $unit-default-font-spacing;
$unit-default-spacing: $unit-default-spacing;
$unit-touch: $unit-touch;

// settings/colors
$white: $white;
$gray-200: $gray-200;
$gray-300: $gray-300;
$gray-400: $gray-400;
$gray-600: $gray-600;
$all-colors: $all-colors;

// settings/stylistic
$shadow: $shadow;
$shadow-lift: $shadow-lift;
$shadow-lift-light: $shadow-lift-light;

// component vars
$binary-input-base: 40;
$binary-input-button-base: 32;
$binary-input-button-border-radius: 50%;
$binary-input-button-border-width: 2;
$binary-input-button-border-color: $white;
$binary-input-button-colors: $all-colors;
$binary-input-button-checked-icon-shadow: 0 2px 2px rgba($black, 0.3);

.binary-input {
    display: flex;
    align-items: center;
    min-height: rem-calc($binary-input-base);
    margin: rem-calc(0 0 $unit-default-spacing * 2); // same as .input component
    cursor: pointer;
}

.binary-input__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem-calc($binary-input-button-base);
    height: rem-calc($binary-input-button-base);
    box-shadow: $shadow;
    border-color: $binary-input-button-border-color;
    border-style: solid;
    border-width: rem-calc($binary-input-button-border-width);
    border-radius: $binary-input-button-border-radius;
    background: $gray-200;
    line-height: 0;

    .binary-input--checked & {
        box-shadow: $shadow-lift-light;
    }
}

.binary-input__icon {
    width: rem-calc(21);
    height: rem-calc(21);
    margin: 0;
    fill: $white;
    line-height: 0;

    .binary-input--checked & {
        filter: drop-shadow($binary-input-button-checked-icon-shadow);
    }
}

.binary-input--show-icon-checked {
    .binary-input__icon,
    .binary-input__character {
        display: none;
    }

    &.binary-input--checked .binary-input__icon,
    &.binary-input--checked .binary-input__character {
        display: block;
    }
}

.binary-input__character {
    font-size: rem-calc($unit-base-font-size);
    color: $white;
    @include sans-bold;

    .binary-input--checked & {
        text-shadow: $shadow;
    }
}

.binary-input__label {
    margin-left: rem-calc($unit-default-spacing);
}

// color modifiers
@each $color-key, $color in $binary-input-button-colors {
    // unchecked
    .binary-input--unchecked-#{$color-key} .binary-input__button {
        background: $color;
    }

    // checked
    .binary-input--checked.binary-input--checked-#{$color-key}
        .binary-input__button {
        background: $color;
    }

    // always show color
    .binary-input--show-color-unchecked.binary-input--#{$color-key}
        .binary-input__button {
        background: $color !important;
    }
}
