// *******************************************
// *******************************************

// App layout
// Mobile: Fixed header with hamburger
//         flyout menu
// Desktop: Fixed header and sidebar,
//          main content scrolls

// *******************************************
// *******************************************

// @vars
// settings/layout-units
$unit-sm-edge-space: $unit-sm-edge-space;
$unit-touch: $unit-touch;
$unit-default-spacing: $unit-default-spacing;
$unit-default-font-spacing: $unit-default-font-spacing;
$unit-grid-4of12: $unit-grid-4of12;
$unit-sidebar-header-md-height: $unit-sidebar-header-md-height;
$unit-app-content-sidebar-md-width: $unit-app-content-sidebar-md-width;
$unit-app-header-height: $unit-app-header-height;

// settings/z-indexes
$index-app-nav-sm-index: $index-app-nav-sm-index;

// setting/colors
$white: $white;
$gray-050: $gray-050;
$gray-200: $gray-200;
$gray-800: $gray-800;
$secondary: $secondary;

// settings/timing-funcitons
$ease-in-out-quad: $ease-in-out-quad;

// settings/breakpoints
$breakpoint-md: $breakpoint-md;

// component vars
$app-header-height: $unit-app-header-height;
$app-content-sidebar-md-width: $unit-app-content-sidebar-md-width;
$app-content-sidebar-header-md-height: $unit-sidebar-header-md-height;

// app header v2
$unit-app-header-height: $unit-app-header-height;

// app container element
.app {
    position: relative;
    min-height: 100vh;
    background: $white;

    @include min-screen($breakpoint-md) {
        display: flex;
        align-items: flex-start;
        height: calc(100vh - #{rem-calc($unit-app-header-height)});
        padding-top: rem-calc($unit-app-header-height);
        overflow: hidden;
    }
}

////////////////////////////////////////
// App Header2
////////////////////////////////////////
.app__header2 {
    position: relative;
    z-index: $stack-top;
    height: rem-calc($unit-app-header-height);

    // fix to top on large screens
    @include min-screen($breakpoint-md) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }
}

.app__menu {
    position: fixed;
    top: rem-calc($unit-app-header-height);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $stack-top;
    transform: translateX(-100%);
    background: $gray-050;
    transition: transform 250ms $ease-in-out-quad, visibility 250ms ease,
        opacity 250ms ease;

    @include min-screen($breakpoint-md) {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        transform: none;
        background: transparent;
        transition: none;
    }
}

// mobile visibility modifier
.app-header__app-nav--visible {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
}

.app-header-z-index {
    z-index: 0;
}
////////////////////////////////////////
// App Content
////////////////////////////////////////
.app__content {
    position: relative;
    z-index: $index-app-content;
    padding-bottom: rem-calc($unit-touch + ($unit-touch * 0.5));

    @include min-screen($breakpoint-md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        flex: 1 0 auto;
        width: 100%;
        height: calc(100vh - #{rem-calc($unit-app-header-height)});
        padding-bottom: 0;
    }
}

.app__flash {
    position: relative;
    z-index: $stack-bot;
    height: rem-calc($app-header-height);
    border-bottom: 1px solid $gray-200;
    background: $gray-050;
    + .app__content__page {
        height: calc(100% - #{rem-calc($unit-app-header-height)});
    }

    @include min-screen($breakpoint-md) {
        flex: 1 0 100%;
        min-height: rem-calc($unit-touch);
    }

    &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: rem-calc($unit-default-spacing * 2);
        opacity: 0.15;
        background-image: linear-gradient(
            -180deg,
            rgba($black, 0) 0%,
            $black 100%
        );
    }
}

.app__flash__alert {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $stack-sub;
    margin: 0;
}

.app__content__page {
    @include min-screen($breakpoint-md) {
        flex: 1 0 calc(100% - #{rem-calc($app-content-sidebar-md-width)});
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }
}
