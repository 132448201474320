// *******************************************
// *******************************************

// Styles for root elements

// *******************************************
// *******************************************

html,
body {
    background: $white;
    font-size: 1rem;
    color: $gray-800;
    line-height: rem-calc(24);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include sans;

    @include min-screen($breakpoint-md) {
        background: $gray-050;
    }
}
